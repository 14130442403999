import React, { useEffect } from "react";
import Modal from "react-modal";

const BuyDM = ({ isModalOpen, setIsModalOpen }) => {
  useEffect(() => {
    if (isModalOpen) {
      // Load PayPal SDK dynamically with data-namespace attribute
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=BAAuNoo7XD3cjJghsvL2XaMcKB0M0ZzfCDIr-qZqINiPGfi0cp7nQsy8DNiV7zfV_fzz5ZHuOlgY2qtt-I&components=hosted-buttons&enable-funding=venmo&currency=USD";
      script.async = true;
      script.crossOrigin = "anonymous";
      script.setAttribute("data-namespace", "paypal_sdk"); // Adding the data-namespace attribute
      document.body.appendChild(script);

      // Initialize PayPal Hosted Buttons once the script is loaded
      script.onload = () => {
        if (window.paypal_sdk) { // Accessing the correct namespace
          window.paypal_sdk.HostedButtons({
            hostedButtonId: "2B2FXPQHQV2HQ", // Your hosted button ID
          }).render("#paypal-container-2B2FXPQHQV2HQ"); // Render the button inside this div
        }
      };

      // Clean up script on modal close
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isModalOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    window.location.reload(); // Refresh the page
  };

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Buy DM"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          color: "#fff",
          backgroundColor: "#2c2c2c",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "600px",
          margin: "50px auto",
          maxHeight: "80vh", // Ensures it won't take more than 80% of viewport height
          overflowY: "auto", // Adds scroll if the content is too long
          position: "relative",
        },
      }}
    >
      <div className="bg-transparent">
        <button
          onClick={handleClose} // Refresh the page after closing the modal
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "transparent",
            border: "none",
            fontSize: "20px",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          &times;
        </button>

        <h1>Buy DM</h1>

        {/* PayPal Hosted Button Container */}
        <div id="paypal-container-2B2FXPQHQV2HQ"></div>
      </div>
    </Modal>
  );
};

export default BuyDM;
